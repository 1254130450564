<template>
  <div class="fullstack" style="cursor: pointer">
    <!-- 首页头部组件 -->
    <header_public :navindex="5"></header_public>
    <div class="maska" v-if="videoState == true" @click="masksCloseFun()"></div>
    <div class="videomasks" v-if="videoState == true">
      <video
        :src="videoSrc"
        controls="controls"
        autoplay
        style="width: 57vw; height: 32vw"
      >
        您的浏览器不支持 video 标签。
      </video>
    </div>
    <div v-else-if="imgState">
      <div class="maska" @click="masksCloseFun()"></div>
      <div class="imagemasks">
        <img :src="imgSrc" controls="controls" autoplay class="show-img" />
      </div>
    </div>
    <!-- 积云全栈专业 -->
    <div class="jiyunfs">
      <div class="fullw">
        <p class="full1"><br />全栈专业</p>
        <p class="full2">
          全栈工程师是指掌握多种技能、胜任前端与后端、能利用多种技能独立完成产品开发的人，即全能型IT人才。
          <br>
          一个现代化的项目，是一个非常复杂的构成，企业需要全能型人才来掌控全局，前后端通吃的人更匹配企业的用人需求，故全栈工程师在企业中会得到更多的晋升机会和丰厚的薪酬待遇。
        </p>
      </div>
      <div class="fullb">
        <img src="../../assets/images/fullB.png" alt />
      </div>
    </div>
    <!-- 学it -->
    <p class="p1">学IT为什么选择全栈</p>
    <div class="study">
      <dl>
        <dt>
          <img src="../../assets/images/yun.png" alt />
        </dt>
        <dd class="dd1">全局思维沟通成本低</dd>
        <dd class="dd2">多样编码技能</dd>
        <dd class="dd2">全局思维能力</dd>
        <dd class="dd2">1+1＞2</dd>
      </dl>
      <dl>
        <dt>
          <img src="../../assets/images/zhuanhuan.png" alt />
        </dt>
        <dd class="dd1">多元化工作机会</dd>
        <dd>APP开发工程师</dd>
        <dd>小游戏开发工程师</dd>
        <dd>全栈工程师</dd>
        <dd>小程序开发工程师</dd>
      </dl>
      <dl>
        <dt>
          <img src="../../assets/images/share.png" alt />
        </dt>
        <dd class="dd1">丰厚的待遇</dd>
        <dd>北京 ¥24310</dd>
        <dd>上海 ¥20270</dd>
        <dd>深圳 ¥19990</dd>
        <dd>天津 ¥16420</dd>
      </dl>
    </div>
    <!-- 前景介绍 -->
    <p class="p2" style="margin-top: 9vw">前景介绍</p>
    <p class="p3">
      新时代的前端，一次开发同时适用于android、ios、web、watch等平台，
      可以开发网站、移动端web程序、数据可视化，前景广阔。
    </p>
    <div
      class="futurevideo"
      @click="checkVideoFun(majorvideo[0] ? majorvideo[0]['image'] : '')"
    >
      <!-- <img :src="majorvideo[0] ? majorvideo[0]['imageThumb'] : ''" alt class="videoimg"/> -->
      <img
        class="videoimg"
        src="../../assets/images/video-text-button.png"
        alt
      />
      <img class="ladyimg" src="../../assets/images/lady.png" alt />
    </div>
    <div class="futureword">
      <ul>
        <!--        <li class="futureli1">搜索功能</li>-->
        <li class="futureli2 futureli">市场亟需、企业渴求</li>
        <li class="futureli3 ">
          一个现代项目的开发，是一个非常复杂的构成，对于一个团队特别是互联网企业来说，有一个全局性思维的人非常重要
        </li>
      </ul>
      <ul>
        <!--        <li class="futureli1">搜索功能</li>-->
        <li class="futureli2 futureli">全端全栈</li>
        <li class="futureli3">
          多语言开发能力；多平台运作和大技术融合能力；性能跟踪、究级Bug清除能力；
          <br />模型开拓和架构设计能力； <br />项目管理和产品原型设计能力
        </li>
      </ul>
      <ul>
        <!--        <li class="futureli1">搜索功能</li>-->
        <li class="futureli2 futureli">兼容拓展、游刃有余</li>
        <li class="futureli3">
          面对各个职能岗位的挑战,都能泰然处之；不管是技术线,管理线,甚至是业务线,都能胜任
        </li>
      </ul>
    </div>
    <!-- 从小白到~ -->
    <p class="p4">从“小白”到全栈开发工程师</p>
    <!-- <ul class="littleul">
                        <li style="color:#FC4B38">——→</li>
                        <li style="color:#FC4B38">技术</li>
                        <li>品牌推广</li>
                        <li>营销学</li>
                        <li>网页设计</li>
    </ul>-->
    <div class="littlebai">
      <img src="../../assets/images/h4-holder-tab-img-1.png" alt />
      <div>
        <p>
          分段课程体系
          <br />
          <span style="color: red">循序渐进</span>学习
        </p>
        <ul>
          <li>
            <span
              style="color: #fff; display: inline-block; margin-right: 0.5vw"
              >✔</span
            >
            第一阶段 web网页开发
          </li>
          <li>
            <span
              style="color: #fff; display: inline-block; margin-right: 0.5vw"
              >✔</span
            >
            第二阶段 移动web网页开发
          </li>
          <li>
            <span
              style="color: #fff; display: inline-block; margin-right: 0.5vw"
              >✔</span
            >
            第三阶段 JavaScript初级
          </li>
          <li>
            <span
              style="color: #fff; display: inline-block; margin-right: 0.5vw"
              >✔</span
            >
            第四阶段 JavaScript高级
          </li>
          <li>
            <span
              style="color: #fff; display: inline-block; margin-right: 0.5vw"
              >✔</span
            >
            第五阶段 web全栈开发
          </li>
          <li>
            <span
              style="color: #fff; display: inline-block; margin-right: 0.5vw"
              >✔</span
            >
            第六阶段 Vue框架实战
          </li>
          <li>
            <span
              style="color: #fff; display: inline-block; margin-right: 0.5vw"
              >✔</span
            >
            第七阶段 企业级项目实战
          </li>
        </ul>
      </div>
    </div>
    <!-- 真就业好发展 -->
    <div class="real">
      <div class="real1">
        <p class="realbigword">就业好、发展好</p>
        <!--                <p style="margin-top:0.5vw">数千名学员就业单位真实可查</p>-->
        <ul>
          <li>
            <span
              style="
                color: #fff;
                display: inline-block;
                margin-right: 0.2vw;
                font-size: 1.3rem;
              "
              >▪</span
            >数千名学员就业单位真实可查
          </li>
          <li>
            <span
              style="
                color: #fff;
                display: inline-block;
                margin-right: 0.2vw;
                font-size: 1.3rem;
              "
              >▪</span
            >专职就业指导讲师为您求职铺路
          </li>
          <li>
            <span
              style="
                color: #fff;
                display: inline-block;
                margin-right: 0.2vw;
                font-size: 1.3rem;
              "
              >▪</span
            >技术导师提供124天专属服务
          </li>
          <li>
            <span
              style="
                color: #fff;
                display: inline-block;
                margin-right: 0.2vw;
                font-size: 1.3rem;
              "
              >▪</span
            >专人专岗，打造私人订制
          </li>
        </ul>
      </div>
      <img src="../../assets/images/h4-holder-single-img-1.png" alt />
    </div>
    <!-- 战略图 -->
    <!-- <p class="p4">我们的战略和路线图</p>
                    <p class="p5">
                        Proin eget purus augue。Nam tempor dapibus sapien，ut posuere magna malesuada等。Maecenas consectetur，sem vitae dignissim commodo，erat。
                    </p>
    <img class="timeimg" src="../../assets/images/time.png" alt="">-->
    <div class="futureword">
      <ul style="background: #fff; height: 12.18vw">
        <li
          class="futureli2"
          style="text-align: left; color: #080f4d; margin-left: 2.4vw"
        >
          核心知识
        </li>
        <li class="futureli3" style="color: #080f4d; font-size: 1.125rem">
          HTML5、CSS3、Flex、sass、动画、rem、媒体查询、响应式、Bootstrap、移动端调试、真机调试、NodeJS、MongoDB、Git Vue、组件、Vuex-cli、前端工程化webpak、ES6
        </li>
      </ul>
      <ul style="background: #fff; height: 12.18vw; width: 32.5%">
        <li
          class="futureli2"
          style="text-align: left; color: #080f4d; margin-left: 2vw"
        >
          实战项目
        </li>
        <li class="futureli3" style="color: #080f4d; font-size: 1.125rem">
          严选商城、京东商城、饿了么外卖、优站精选
          员工信息管理系统、任务管理应用、移动端交互特效
          阿里百秀、博客系统、B2C电商后台管理系统、B2C商城前端购物商城
        </li>
      </ul>
      <ul style="background: #fff; height: 12.18vw">
        <li
          class="futureli2"
          style="text-align: left; color: #080f4d; margin-left: 2vw"
        >
          能力目标
        </li>
        <li class="futureli3" style="color: #080f4d; font-size: 1.125rem">
          掌握HTML5常用标签、 掌握CSS语法及使用技巧、 掌握基本的动画效果、
          能够掌握Web项目的部署与发布模式、
          掌握使用echarts进行数据可视化交互开发、 能够知道Vue的主流技术栈的使用
        </li>
      </ul>
    </div>

    <!-- 认识专业人士 -->
    <!--        <p class="pp">从业经验十年以上，拥有丰富的行业知识和管理经验</p>-->
    <p class="p2" style="margin-bottom: 3.5vw">骨干讲师介绍</p>
    <!--        <img style="margin:0 auto;margin-top:1vw;margin-bottom:3.5vw" src="../../assets/images/btzst.png" alt/>-->
    <!-- 教师团队轮播 -->
    <div class="swiper_con con_style" style="cursor: pointer">
      <!-- 轮播图 -->
      <div class="bannerWrap">
        <!--style="width:100%;height:300px;" -->
        <!-- Swiper -->
        <div
          class="swiper-container teacher-con"
          @mousemove="stop(swiperTracher)"
          @mouseleave="start(swiperTracher)"
        >
          <div class="swiper-wrapper">
            <div
              class="swiper-slide teachers_box"
              v-for="(teacher, index) in teacherlist"
              :key="index"
            >
              <a href="javascript:void(0)" class="enlarge">
                <img
                  :src="teacher.imageThumb ? teacher.imageThumb : teacher.image"
                  alt
                />
              </a>
              <div class="teacher_team_con">
                <!-- <div class="team_con_top flex_style direction_row flex_nowrap justify_end align_center">
                                    <span class="text_family">大数据专业</span>
                                    <img src="../../assets/images/quanping.png" alt="">
                </div>-->
                <div class="team_con_bottom text_left">
                  <p class="text_family">
                    {{ teacher.title ? teacher.title : '' }}
                  </p>
                  <span class="text_family">{{
                    teacher.extend ? teacher.extend.subtitle : ''
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- Add Pagination -->
          <div
            class="swiper-pagination swiper-pagination-white major-spit teach-spit"
            id="swiper-spit"
          ></div>
        </div>
      </div>
      <!-- Add Arrows -->
      <div
        class="swiper-button-next swiper-button-white teachers-switch-next"
      ></div>
      <div
        class="swiper-button-prev swiper-button-white teachers-switch-prev"
      ></div>
    </div>
    <!-- 正确的解决方案 -->
    <div class="solve">
      <div>
        <p>正确的解决方案</p>
        <img class="solveimg" src="../../assets/images/jindu.png" alt />
      </div>
      <img
        class="img2"
        src="../../assets/images/h4-holder-single-img-2.png"
        alt
      />
    </div>
    <!-- 5.0新课程 -->
    <!-- 开源的 -->
    <div class="kaiyuan">
      <div class="kaiyuanbox">
        <img src="../../assets/images/suo.png" alt />
        <ul>
          <li
            style="
              color: #fff;
              font-size: 1.5rem;
              font-family: PingFang;
              text-align: left;
            "
          >
            零基础入门
          </li>
          <li
            style="
              color: #959ee7;
              font-family: PingFang;
              text-align: left;
              margin-top: 0.5vw;
              line-height: 1.14vw;
            "
          >
            没有任何的基础也可以成为一名优秀的开发工程师，针对" 零 "基础学员开发的易懂，易学的专属课程。
          </li>
        </ul>
      </div>
      <div class="kaiyuanbox">
        <img src="../../assets/images/safe.png" alt />
        <ul>
          <li
            style="
              color: #fff;
              font-size: 1.5rem;
              font-family: PingFang;
              text-align: left;
            "
          >
            课程全面升级
          </li>
          <li
            style="
              color: #959ee7;
              font-family: PingFang;
              text-align: left;
              margin-top: 0.5vw;
              line-height: 1.14vw;
            "
          >
            六大优势为您保驾护航，零基础入门，课程生动，实时更新，项目实战，讲练结合。
          </li>
        </ul>
      </div>
      <div class="kaiyuanbox">
        <img src="../../assets/images/gou.png" alt />
        <ul>
          <li
            style="
              color: #fff;
              font-size: 1.5rem;
              font-family: PingFang;
              text-align: left;
            "
          >
            分段式学习
          </li>
          <li
            style="
              color: #959ee7;
              font-family: PingFang;
              text-align: left;
              margin-top: 0.5vw;
              line-height: 1.14vw;
            "
          >
            PC、移动、响应式Web开发 Web江湖编程与全栈开发，框架学习，项目提升。
          </li>
        </ul>
      </div>
    </div>
    <div class="kaiyuan">
      <div class="kaiyuanbox">
        <img src="../../assets/images/comp.png" alt />
        <ul>
          <li
            style="
              color: #fff;
              font-size: 1.5rem;
              font-family: PingFang;
              text-align: left;
            "
          >
            专业的就业指导
          </li>
          <li
            style="
              color: #959ee7;
              font-family: PingFang;
              text-align: left;
              margin-top: 0.5vw;
              line-height: 1.14vw;
            "
          >
            一对多模拟面试，一位学员多次模拟，为找到工作做充分准备，7*24小时在线服务答疑，帮助学员解决工作中遇到的问题，保证安全度过试用期。
          </li>
        </ul>
      </div>
      <div class="kaiyuanbox">
        <img src="../../assets/images/free.png" alt />
        <ul>
          <li
            style="
              color: #fff;
              font-size: 1.5rem;
              font-family: PingFang;
              text-align: left;
            "
          >
            就业方向多元化
          </li>
          <li
            style="
              color: #959ee7;
              font-family: PingFang;
              text-align: left;
              margin-top: 0.5vw;
              line-height: 1.14vw;
            "
          >
            App开发工程师，小游戏开发工程师，Java开发工程师，全栈工程师，Html5开发工程师，小程序开发工程师，Web前端开发工程师.....
          </li>
        </ul>
      </div>
      <div class="kaiyuanbox">
        <img src="../../assets/images/last.png" alt />
        <ul>
          <li
            style="
              color: #fff;
              font-size: 1.5rem;
              font-family: PingFang;
              text-align: left;
            "
          >
            一线大城市就业
          </li>
          <li
            style="
              color: #959ee7;
              font-family: PingFang;
              text-align: left;
              margin-top: 0.5vw;
              line-height: 1.14vw;
            "
          >
            一线城市，如北京、上海、广州、深圳；
            <br />新一线城市，如天津、成都、杭州、苏州、重庆等。
          </li>
        </ul>
      </div>
    </div>
    <!-- 未来的采矿平台 -->
    <!-- <div class="stage">
      <p class="stagep">未来的采矿平台!</p>
      <ul class="stageul">
        <li><span style="color:#fff">✔</span> 电子商城</li>
        <li><span style="color:#fff">✔</span> 社交应用</li>
        <li><span style="color:#fff">✔</span> 房产租赁</li>
      </ul>
      <ul class="stageul">
        <li><span style="color:#fff">✔</span> 金融理财</li>
        <li><span style="color:#fff">✔</span> 求职招聘</li>
        <li><span style="color:#fff">✔</span> 视频影视</li>
      </ul>
      <ul class="stageul">
        <li><span style="color:#fff">✔</span> 旅游酒店</li>
        <li><span style="color:#fff">✔</span> 美食外卖</li>
        <li><span style="color:#fff">✔</span> 视频直播</li>
      </ul>
    </div> -->
    <!-- 学员作品 -->
    <!--        <p class="workp">我们的工作流程</p>-->
    <p class="workp1" style="margin-top: 1vw">学员作品</p>
    <div class="workflex" style="cursor: pointer">
      <img
        :src="projectlist[0] ? projectlist[0]['imageThumb'] : ''"
        alt
        @click="checkimgFun(projectlist[0] ? projectlist[0]['image'] : '')"
      />
      <img
        :src="projectlist[1] ? projectlist[1]['imageThumb'] : ''"
        alt
        @click="checkimgFun(projectlist[1] ? projectlist[1]['image'] : '')"
      />
      <img
        :src="projectlist[2] ? projectlist[2]['imageThumb'] : ''"
        alt
        @click="checkimgFun(projectlist[2] ? projectlist[2]['image'] : '')"
      />
    </div>
    <div class="workflex" style="cursor: pointer">
      <img
        :src="projectlist[3] ? projectlist[3]['imageThumb'] : ''"
        alt
        @click="checkimgFun(projectlist[3] ? projectlist[3]['image'] : '')"
      />
      <img
        :src="projectlist[4] ? projectlist[4]['imageThumb'] : ''"
        alt
        @click="checkimgFun(projectlist[4] ? projectlist[4]['image'] : '')"
      />
      <img
        :src="projectlist[5] ? projectlist[5]['imageThumb'] : ''"
        alt
        @click="checkimgFun(projectlist[5] ? projectlist[5]['image'] : '')"
      />
    </div>
    <!-- 全栈就业方向 -->
    <div class="full">
      <!--            <p class="workp">技术领域</p>-->
      <p class="workp2">就业方向</p>
    </div>
    <div class="fullflex">
      <div>
        <ul>
          <li>微信小程序</li>
          <li>覆盖行业最广的职位之一</li>
        </ul>
        <img src="../../assets/images/technonogy-1.png" alt />
      </div>
      <div>
        <ul>
          <li>WEB 前端</li>
          <li>市场需求大，平均就业待遇高达15K</li>
        </ul>
        <img src="../../assets/images/technonogy-2.png" alt />
      </div>
    </div>
    <div class="fullflex">
      <div>
        <ul>
          <li>H5酷炫页面</li>
          <li>
            酷炫的H5页面穿插在各行业App中，已经成为互联网产品不可或缺的部分
          </li>
        </ul>
        <img src="../../assets/images/technonogy-3.png" alt />
      </div>
      <div>
        <ul>
          <li>移动端工程师</li>
          <li>移动设备的时代，移动智能设备开发已成必然</li>
        </ul>
        <img src="../../assets/images/technonogy-4.png" alt />
      </div>
    </div>
    <div class="fullflex">
      <div>
        <ul>
          <li>......</li>
          <li>全栈专业就业覆盖面广，面对多个行业培养和输出 专业人才</li>
        </ul>
        <img src="../../assets/images/technonogy-3.png" alt />
      </div>
    </div>
    <!-- 就业辅导 -->
    <div class="job">
      <div class="jobword">
        <!--        <p class="workp">新闻与更新</p>-->
        <p class="workp2" style="margin-top: 1vw">就业辅导</p>
        <ul>
          <li>一对一模拟面试，为找工作做充分准备</li>
          <li>
            7*24在线服务答疑，帮助学员解决工作中遇到的问题，保证安全度过试用期
          </li>
          <li>一对一面试复盘，找到问题所在，帮助学员找到满意的工作</li>
        </ul>
      </div>
      <img src="../../assets/images/home-3-inner-rev-1-img-1.png" alt />
    </div>
    <!-- 毕业感言-->
    <div class="recollections">
      <!-- <recollections_publc major="全栈开发"></recollections_publc> -->
    </div>

    <!-- 底部 -->
    <bottom_public></bottom_public>
  </div>
</template>
<script>
import Swiper from 'swiper/js/swiper.min.js';
import 'swiper/css/swiper.min.css';
import bottom_public from '../../components/bottom_public/bottom_public';
import header_public from '../../components/header_public/header_public';
import recollections_publc from '../../components/recollections_publc/recollections_publc';

const axios = require('axios').default;
export default {
  metaInfo: {
    title: '全栈开发|全栈开发培训|全栈工程师培训-积云教育',
    meta: [
      {
        name: 'description',
        content:
          '积云教育全栈开发培训，紧贴时代需求，精心设置全栈开发课程，为时代发展培养优秀全栈工程师人才。',
      },
      {
        name: 'keywords',
        content:
          '全栈开发,全栈开发培训,全栈工程师培训,HTML5培训,全栈开发培训机构',
      },
    ],
  },
  created() {
    this.getteacherlist(); //获取教师团队列表
    this.getprojectlist(); //获取学员列表
    this.getmajorvideo(); //获取专业宣传视频
  },
  data() {
    return {
      projectlist: {},
      videoSrc: '',
      videoState: false,
      imgSrc: '',
      imgState: false,
      majorvideo: '',
      teacherlist: {},
      swiperTracher: null,
    };
  },
  methods: {
    //获取学员作品
    getprojectlist() {
      axios
        .get(
          '/api/block/getItemsByName?name=' +
            encodeURIComponent('学员作品') +
            '&major=' +
            encodeURIComponent('全栈开发'),
        )
        .then((res) => {
          this.projectlist = res.data.data; //学员作品列表数据
        });
    },
    getmajorvideo() {
      axios
        .get(
          '/api/block/getItemsByName?name=' +
            encodeURIComponent('专业宣传') +
            '&major=' +
            encodeURIComponent('全栈开发'),
        )
        .then((res) => {
          this.majorvideo = res.data.data; //学员感言列表数据
        });
    },
    //获取专业教师
    getteacherlist() {
      axios
        .get(
          '/api/block/getItemsByName?name=' +
            encodeURIComponent('教师团队') +
            '&major=' +
            encodeURIComponent('全栈开发'),
        )
        .then((res) => {
          this.teacherlist = res.data.data; //教师团队列表数据
        });
    },
    checkVideoFun(src) {
      this.videoState = true;
      this.videoSrc = src;
    },
    checkimgFun(src) {
      this.imgState = true;
      this.imgSrc = src;
    },
    masksCloseFun() {
      this.videoState = false;
      this.imgState = false;
    },
  },
  components: {
    bottom_public,
    header_public,
    recollections_publc,
  },
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    // window.pageYOffset = 0;
    this.swiperTracher = new Swiper('.teacher-con', {
      slidesPerView: 4,
      slidesPerGroup: 4,
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: false, //修改swiper的父元素时，自动初始化swiper
      autoplay: {
        delay: 2000,
        disableOnInteraction: false, // 取消鼠标操作后的轮播暂停【无操作轮播继续】【参考链接1】
        stopOnLastSlide: false, // 在最后一页停止
      },
      pagination: {
        el: '.teach-spit',
        clickable: true,
      },
      navigation: {
        nextEl: '.teachers-switch-next',
        prevEl: '.teachers-switch-prev',
      },
    });
  },
};
</script>
<style scoped>
.fullstack >>> .evaluate {
  background: none !important;
}

.fullstack >>> .con_title h4 {
  color: #ffffff !important;
}

.recollections >>> .con_title span,
.recollections >>> .con_title h4 {
  color: #ffffff !important;
}
</style>
<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
}

#header {
  // background:linear-gradient(90deg,rgba(211,12,157,1),rgba(51,14,130,1),rgba(211,12,157,1));
  background: linear-gradient(
    90deg,
    rgba(211, 12, 157, 1),
    rgba(35, 51, 179, 1),
    rgba(135, 75, 131, 1)
  );
}

.fullstack {
  width: 100%;
  background: url('~@/assets/images/fullbg.png');
  // position: fixed;
  // padding-top: 15vw;
}

// 积云全栈专业
.jiyunfs {
  width: 62.5vw;
  height: 40vw;
  text-align: center;
  // background-color: aquamarine;
  margin: 0 auto;
  position: relative;
}

.fullw {
  width: 36.6vw;
  height: 14.3vw;
  // margin: 0 auto;
  // margin-top: 16vw;
}

.fullb img {
  width: 41.25vw;
  height: 40.78vw;
  position: absolute;
  top: 0;
}

.full1 {
  width: auto;
  height: auto;
  font-size: 4.6rem;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: rgba(255, 255, 255, 1);
  line-height: 4.23vw;
  text-align: left;
  // margin-top: 4vw;
}

.full2 {
  width: 36.6vw;
  height: 5.36vw;
  font-size: 1rem;
  font-family: PingFang;
  font-weight: 500;
  margin-top: 4vw;
  color: rgba(247, 241, 245, 1);
  line-height: 1.6vw;
  text-align: left;
  word-wrap: break-word;
}

// 学it
.p1 {
  width: auto;
  height: auto;
  font-size: 2.75rem;
  font-family: PingFang;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  margin: 0 auto;
}

.study {
  width: 62.5vw;
  height: 15vw;
  margin: 0 auto;
  margin-top: 5vw;
  display: flex;
  justify-content: space-between;
}

.study dl {
  width: 33%;
  height: 100%;
}

.study dl .dd1 {
  width: 100%;
  height: 1.19vw;
  font-size: 1.5rem;
  font-family: PingFang;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  line-height: 1.19px;
  margin-top: 2vw;
  text-align: center;
}

.study dd {
  width: 100%;
  height: 1vw;
  font-size: 1.2rem;
  font-family: PingFang;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  line-height: 1.3vw;
  margin-bottom: 0.5vw;
  margin-top: 0.5vw;
  text-align: center;
}

// 前景介绍
.p2 {
  font-size: 2.75rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  margin: 0 auto;
  // margin-top: 10.5vw;
  margin-top: 5vw;
}

.p3 {
  width: 42.2vw;
  height: 2.86vw;
  font-size: 1.5rem;
  font-family: PingFang;
  font-weight: 500;
  color: #fff;
  line-height: 1.61vw;
  margin: 0 auto;
  margin-top: 1vw;
  text-align: left;
  line-height: 2vw;
}

.futurevideo {
  width: 62.5vw;
  height: 32.39vw;
  margin: 0 auto;
  margin-top: 3vw;
  // background-color: aqua;
  position: relative;
}

.videoimg {
  width: 45vw;
  height: 28.69vw;
  // margin-right: 18vw;
  margin: 0 auto;
}

.ladyimg {
  width: 29vw;
  height: 17.3vw;
  position: absolute;
  right: 10vw;
  bottom: -2vw;
}

.futurebg {
  width: 100%;
  height: 44vw;
  background: url('~@/assets/images/bluesky.png') no-repeat center;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}

.futureword {
  width: 62.5vw;
  // height: 14vw;
  height: 22vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 5vw;
}
.fullstack .p4 {
  font-size: 2.75rem !important;
}

.futureword ul {
  width: 30%;
  // height: 100%;
  background: rgba(42, 59, 191, 1);
  border-radius: 2px;
  margin-top: 4vw;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.futureword ul:hover,
.fullflex div:hover {
  transform: translateY(-20px);
  transition: all 0.5s;
  box-shadow: 0px 4px 20px -6px rgba(0, 0, 0, 0.5);
}
.fullflex ul li{
  line-height: 1.5rem;
}
.futureword .futureli {
  margin-top: 5vw!important;
}
.futureword .futureli1 {
  width: 4vw;
  font-size: 0.6rem;
  font-family: PingFang;
  font-weight: 700;
  color: rgba(66, 106, 245, 1);
  line-height: 1.04vw;
  margin-top: 2vw;
  margin-left: 1.5vw;
}

.futureword .futureli2 {
  height: 1.19vw;
  font-size: 1.5rem;
  font-family: PingFang;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  line-height: 1.04vw;
  margin-top: 1vw;
  text-align: left;
  margin-left: 1.8vw;
}

.futureword .futureli3 {
  width: 16.45vw;
  // height: 2.76vw;
  font-size: 1rem;
  font-family: PingFang;
  font-weight: 400;
  color: rgba(215, 219, 243, 1);
  line-height: 1.4vw;
  text-align: left;
  margin-top: 1vw;
  margin-left: 2vw;
  padding-right: 0.5vw;
  box-sizing: border-box;
}

//从小白到~
.p4 {
  font-size: 2.5rem;
  font-family: PingFang;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  margin: 0 auto;
  margin-top: 6vw;
}

// .littleul{
//     width: 45vw;
//     height: 1vw;
//     margin: 0 auto;
//     display: flex;
//     justify-content: space-around;
//     margin-top:4vw;
//     color: #fff;
// }
.littlebai {
  width: 62.5vw;
  margin: 0 auto;
  margin-top: 4vw;
  display: flex;
  justify-content: space-between;
}

.littlebai img {
  width: 30.52vw;
  height: 19.32vw;
}

.littlebai div {
  margin-left: 6vw;
}

.littlebai p {
  // width: 20.7vw;
  line-height: 2.3vw;
  font-size: 2.18rem;
  color: #fff;
  text-align: left;
}

.littlebai ul {
  width: 20vw;
  height: 8vw;
  color: #fff;
  margin-top: 3vw;
  text-align: left;
  font-size: 1.125rem;
}

.littlebai li {
  margin-top: 1vw;
}

// 真就业 好发展
.real {
  width: 62.5vw;
  height: 30vw;
  margin: 0 auto;
  margin-top: 10vw;
  display: flex;
}

.real .realbigword {
  font-size: 2.75rem;
  font-family: PingFang;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  margin-top: 5vw;
}

.real p {
  width: 20.7vw;
  color: #fff;
  text-align: left;
}

.real ul {
  /*width: 10vw;*/
  height: 8vw;
  color: #fff;
  margin-top: 1.5vw;
  text-align: left;
}

.real li {
  // list-style-type: square;
  margin-top: 0.5vw;
}

.real img {
  width: 29.37vw;
  height: 27.96vw;
  margin-left: 6vw;
}

// 战略图
.p5 {
  width: 36.45vw;
  font-size: 1rem;
  font-family: PingFang;
  font-weight: 800;
  color: rgba(190, 197, 250, 1);
  line-height: 1.19vw;
  margin: 0 auto;
  margin-top: 2vw;
}

.timeimg {
  width: 62.5vw;
  height: 7.55vw;
  margin: 0 auto;
  margin-top: 3.5vw;
}

//认识专业人士
// .major{
//     width: 62.5vw;
//     height: 40vw;
//     margin: 0 auto;
//     margin-top: 4vw;
//     display: flex;
//     justify-content: space-around;
//     flex-wrap: wrap;
// }
// .major img{
//     width: 14.1vw;
//     height: 19vw;
// }
// .dd1{
//     width:5vw;
//     font-size:0.8rem;
//     font-family:PingFang;
//     font-weight:800;
//     color:rgba(66,106,245,1);
//     line-height:1.04vw;
//     text-align: left;
// }
// .dd2{
//     width:12vw;
//     font-size:1.35vw;
//     font-family:PingFang;
//     font-weight:700;
//     color:rgba(255,255,255,1);
//     line-height:1.04vw;
//     margin-top:1vw;
//     text-align: left;
// }
//我们的教师团队
.pp {
  font-size: 0.87rem;
  font-family: PingFang;
  font-weight: 500;
  color: #fff;
  margin: 0 auto;
  margin-top: 5vw;
}

.p2 {
  font-size: 2.75rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #fff;
  margin: 0 auto;
  margin-top: 2vw;
}

//教师团队轮播
.swiper_con {
  position: relative;
}

.con_style {
  width: 62.5vw !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.teacher-con {
  height: 19.8vw !important;
}

.teachers_box {
  width: 15.625vw !important;
  height: 15.625vw !important;
  position: relative;
  border-radius: 5px;
  margin-right: 0 !important;
}

.teachers_box a {
  display: inline-block;
  width: 92% !important;
  height: 100% !important;
  overflow: hidden;
}

.teachers_box img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  transition: all 1s; /*图片放大过程的时间*/
  position: relative;
}

.teachers_box img:hover {
  transform: scale(1.1); /*以y轴为中心旋转*/
}

.teacher_team_con {
  width: 92%;
  height: 16%;
  position: absolute;
  //    top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  padding: 0 14px;
  margin: auto;
  background-color: #000;
  opacity: 0.5;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}

.team_con_bottom p {
  font-size: 1.35rem;
  font-weight: bold;
  color: #ffffff;
}

.team_con_bottom span {
  font-size: 0.875rem;
  font-weight: bold;
  color: #ffffff;
  line-height: 1.8vw;
}

.text_left {
  text-align: justify !important;
}

.text_family {
  font-family: PingFang;
}

.major-spit {
  --swiper-theme-color: #cacaca;
  --swiper-pagination-color: #e11e2c; /* 两种都可以 */
}

.teachers-switch-next,
.teachers-switch-prev {
  border-radius: 50%;
  width: 2.604vw;
  height: 2.604vw;
  background-color: #000000;
  opacity: 1 !important;
}

.teachers-switch-next,
.teachers-switch-prev {
  font-size: 1.125rem !important;
  outline: none;
  top: 36% !important;
}

.teachers-switch-prev {
  left: -4.25rem !important;
}

.teachers-switch-next {
  right: -4.25rem !important;
}

.teachers-switch-next:after,
.teachers-switch-prev:after {
  font-size: 1.125rem;
}

// 正确的解决方案
.solve {
  width: 62.5vw;
  height: 20vw;
  margin: 0 auto;
  margin-top: 8vw;
  display: flex;
  justify-content: space-between;
}

.solve div {
  width: 40%;
  height: 100%;
  text-align: justify;
}

.solve p {
  font-size: 2.75rem;
  font-family: PingFang;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 2vw;
}

.solve .img2 {
  width: 29.63vw;
  height: 18.95vw;
}

.solveimg {
  width: 23.75vw;
  height: 9.94vw;
}

//5.0新课程
.course {
  width: 62.5vw;
  height: 25.5vw;
  margin: 0 auto;
  margin-top: 7vw;
  display: flex;
  justify-content: space-between;
  // background-color: #fff;
  margin-bottom: 6vw;
}

.courseflex {
  width: 50%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.courseflex ul {
  width: 14vw;
  height: 10.78vw;
  background: rgba(42, 59, 191, 1);
  border-radius: 0.125rem;
}

.courseword {
  width: 31%;
  height: 100%;
  text-align: left;
  margin-right: 5vw;
  // background-color: #fff;
}

.p6 {
  font-size: 2.7rem;
  font-family: PingFang;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  margin-top: 3vw;
}

.courseword ul {
  height: 3vw;
  font-size: 0.8rem;
  font-family: PingFang;
  font-weight: 800;
  color: rgba(177, 184, 244, 1);
  line-height: 3vw;
  margin-top: 1.5vw;
}

.courseli {
  width: 50%;
  font-weight: 800;
  color: #fff;
  font-size: 1.25rem;
  font-family: PingFang;
  margin-left: 2.5vw;
  margin-top: 0.5vw;
  text-align: left;
}

.courseli1 {
  width: 64%;
  font-weight: 800;
  color: #fff;
  font-size: 1.12rem;
  font-family: PingFang;
  margin-left: 2.5vw;
  margin-top: 0.5vw;
  text-align: left;
}

//开源的
.kaiyuan {
  width: 62.5vw;
  height: 9vw;
  margin: 0 auto;
  // background-color: #fff;
  margin-top: 4vw;
  display: flex;
  justify-content: space-between;
}

.kaiyuanbox {
  width: 29%;
  height: 98%;
  // background-color: aquamarine;
  display: flex;
}

.kaiyuanbox img {
  width: 3.3vw;
  height: 3.3vw;
  margin-top: 1vw;
  margin-left: 1vw;
}

.kaiyuanbox ul {
  width: 70%;
  margin-top: 1vw;
  margin-left: 1vw;
}

//未来的采矿平台
.stage {
  width: 85vw;
  height: 46.6vw;
  background-image: url('../../assets/images/kuang.png');
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  background-repeat: no-repeat;
  // margin-left: 14vw;
  margin: 0 auto;
  margin-top: 5vw;
}

.stagep {
  /*height: 6vw;*/
  font-size: 2.75rem;
  font-family: PingFang;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  padding-top: 15vw;
  /*padding-bottom: 1vw;*/
  margin-left: 11.6vw;
}

.stageul {
  display: flex;
  margin-top: 1vw;
  margin-left: 11vw;
  font-size: 0.87rem;
  color: #959ee7;
}

.stageul li {
  margin-left: 0.7vw;
}

//学员作品
.workp {
  font-size: 0.87rem;
  font-family: PingFang;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  margin-top: 8vw;
}

.workp1 {
  font-size: 2.75rem;
  font-family: PingFang;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  /*text-shadow: 4px 4px 1px rgb(43, 38, 38);*/
}

.workflex {
  width: 62.5vw;
  margin: 0 auto;
  margin-top: 3vw;
  display: flex;
  justify-content: space-between;
}

.workflex img {
  width: 18vw;
  height: 16vw;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 12px 26px 1px rgba(206, 212, 239, 0.63);
  border-radius: 36px 0px 36px 0px;
}

//全栈就业方向
.full {
  margin-top: 10vw;
}

.workp2 {
  font-size: 2.75rem;
  font-family: PingFang;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  text-align: center;
}

.fullflex {
  width: 62.5vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.fullflex div {
  width: 30vw;
  height: 12vw;
  background-color: #959ee7;
  display: flex;
  justify-content: space-around;
  background: rgba(160, 160, 160, 0.1);
  border: 1px solid rgba(119, 97, 195, 1);
  box-shadow: 0px 3px 5px 1px rgba(35, 31, 70, 0.2);
  border-radius: 5px;
  margin-top: 3vw;
}

.fullflex div img {
  width: 12vw;
  height: 9.3vw;
  margin-top: 2vw;
}

.fullflex div li {
  // width: 10vw;
  margin-top: 2vw;
  text-align: left;
}

.fullflex li:first-child {
  font-size: 1.5rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}

.fullflex li:last-child {
  width: 10vw;
  font-size: 1rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}

.job {
  width: 62.5vw;
  margin: 0 auto;
  margin-top: 10vw;
  display: flex;
  justify-content: space-between;
}

.job p {
  text-align: left;
}

.job img {
  width: 30vw;
  height: 30vw;
}

.job ul {
  width: 28vw;
  margin-top: 3vw;
  list-style-type: square;
  font-family: PingFang;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  line-height: 1.5vw;
  text-align: left;
  font-size: 1.37rem;
  margin-left: 1.5vw;
}

.job li {
  margin-top: 1vw;
}

//
.flex_style {
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

.direction_column {
  flex-direction: column;
}

.flex_nowrap {
  flex-wrap: nowrap;
}

.justify_between {
  justify-content: space-between;
}

.arrowBox {
  position: absolute;
  right: 12px;
  top: 128px;
}

.arrowBox img {
  width: 50px;
  height: 50px;
  margin-left: 10px;
}

.maska {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: #000000;
  opacity: 0.6;
}

.videomasks {
  max-width: 62vw;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 20;
  transform: translate(-50%, -50%);
}

.videomasks video {
  width: 100%;
  height: 100%;
}

.imagemasks {
  max-width: 1000px;
  position: fixed;
  left: 50%;
  top: 58%;
  overflow: auto;
  height: 550px;
  z-index: 20;
  transform: translate(-50%, -50%);
}

.imagemasks::-webkit-scrollbar {
  display: none;
}

.imagemasks img {
  width: 100%;
}
</style>
